import logo from './logo.svg';
import './App.css';
import React, {useCallback} from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import FilePreview from './FilePreview';
import SubmitButtonInput from './SubmitButtonInput';
import ShowOnce from './ShowOnce';




function App() {
	const queryParameters = new URLSearchParams(window.location.search)
	const fiId = queryParameters.get("formInstanceId")

	const d = new Date();

	const [activeStep, setActiveStep] = React.useState(0);
	const [instanceId, setInstanceId] = React.useState(fiId || d.toISOString().split('T')[0] + '-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
	const [loading, setLoading] = React.useState(false);
	const [errors, setErrors] = React.useState(undefined);
	const [snackbarMessage, setSnackbarMessage] = React.useState(undefined);
	const [fiIdFound, setFiIdFound] = React.useState(null);

	const [uploadedFiles, setUploadedFiles] = React.useState([]);
	const [isDeletingFiles, setIsDeletingFiles] = React.useState(false);

	const [ftpServerData, setFtpServerData] = React.useState(false);

	const [acceptedFiles, setAcceptedFiles] = React.useState([]);
	const [acceptedFileExtensions, setAcceptedFileExtensions] = React.useState(window?._tobatec?.acceptedExtensions || ['doc','docx','xls','xlsx','jpg','jpeg','png','gif','bmp','tif','tiff','zip','rar','7z','gz', 'xt','igs','iges','stp','step','dlv','dlv3','exp','session','stl','pfm','elt','ctf','pdf']);
	const [maxFileSize, setMaxFileSize] = React.useState(window?._tobatec?.maxFileSize || 100000000);

	const fileExtensions = acceptedFileExtensions;

	const InputContent = ((files, extra) => (extra.reject ? 'Nur STL-Dateien' : <div>
		Dateien hierher ziehen oder klicken
		<div className='dateitypen'>
			Wir akzeptieren nur die folgenden Dateiendungen: <div className='dateitypen-chips'>{fileExtensions.map((ext) => (<span>{ext}</span>))}</div>
		</div>
		<div className='dateigroesse'>
			Maximale Dateigröße: {humanFileSize(maxFileSize)}
		</div>
		<div className='simultane-dateien-hochladen'>
			Sie können maximal 3 Dateien <em>gleichzeitig</em> hochladen.
		</div>
		</div>));

	// specify upload params and url for your files
	const getUploadParams = ({ meta }) => { return { 
		url: '/anfrage-upload.php?instanceId=' + instanceId,
	} }

	const humanFileSize = (bytes, si=false, dp=1) => {
		const thresh = si ? 1000 : 1024;
	  
		if (Math.abs(bytes) < thresh) {
		  return bytes + ' B';
		}
	  
		const units = si 
		  ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
		  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let u = -1;
		const r = 10**dp;
	  
		do {
		  bytes /= thresh;
		  ++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
	  
	  
		return bytes.toFixed(dp) + ' ' + units[u];
	  }
	
	// called every time a file's `status` changes
	const handleChangeStatus = ({ meta, file }, status) => { 
		if(status == 'error_upload') {
			setSnackbarMessage('Unser Server akzeptiert diese Dateiendung nicht!');
		}

		if(status == 'done') {
			fetchExistingFiles();
		}
		


		console.log(status, meta, file) }

	// receives array of files that are done uploading when submit button is clicked
	const handleSubmit = (files, allFiles) => {
		console.log(files.map(f => f.meta))
		allFiles.forEach(f => f.remove())
	}

	const fetchExistingFiles = () => {
		fetch(`/anfrage-pruefen.php?formInstanceId=${instanceId}`, {
			method: 'GET',
		})
		.then(response => response.json())
		.then(data => {
			console.log(data);
			if(data.status == 'error') {
				setFiIdFound(false);
			} else {
				setFiIdFound(true);

				setUploadedFiles(data.files.map((file) => {
					return {
						id: file.id,
						name: file.name,
						size: file.size
					}
				}));
			}
		})
		.catch((error) => {
			console.error('Error:', error);
			setFiIdFound(false);
		});
	}

	const deleteFile = (fileId) => {
		setIsDeletingFiles(true);

		fetch(`/anfrage-pruefen.php?formInstanceId=${instanceId}&deleteFile=${fileId}`, {
			method: 'GET',
		})
		.then(response => response.json())
		.then(data => {
			console.log(data);
			if(data.status == 'error') {
				setSnackbarMessage('Datei konnte nicht gelöscht werden!');
			} else {
				setUploadedFiles(uploadedFiles.filter((file) => file.id != fileId));
			}
		})
		.catch((error) => {
			console.error('Error:', error);
			setSnackbarMessage('Datei konnte nicht gelöscht werden! Es ist ein Fehler aufgetreten.');
		})
		.finally(() => {
			setIsDeletingFiles(false);
		});
	}



	const formRef = React.useRef(null);

	const handleNext = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setLoading(true);
		

		fetch(`/anfrage-absenden.php?step=${activeStep}&instanceId=${instanceId}`, {
			method: 'POST',
			body: new FormData(formRef.current)
		})
		.then(response => response.json())
		.then(data => {

			if(data.success == false && data.errors) {
				setErrors(data.errors);
				console.log('errors', data.errors);
				return;
			}

			console.log(data);
			setActiveStep(activeStep + 1);
			setErrors(undefined);

			if(activeStep == 2) {
				try {
					window.dataLayer.push({'event': 'form-submitted'});
				} catch(err) {
					console.log(err);
				}
			}
		})
		.finally(() => {
			setLoading(false);
			document.getElementById('root').scrollIntoView();
		});
	};

	React.useEffect(() => {
		if(fiId) {
			fetchExistingFiles();
		}
	}, []);


	if(instanceId === null) {
		return (
			<div className='Anfrageformular'>
				Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
			</div>
		)
	}


  return (
	<div>
	{fiId ? (<div>
		<div className="Anfrageformular-Schritte">
			<div className='Schritte'>
				<div className={'Schritt aktiv'}><span>1.</span> Dateien hinzufügen</div>
			</div>
			<div className='Schritte-Formular'>
				<div className='Schritt-Inhalte'>
					<div className='Schritt-Ende Schritt'>
						{fiIdFound === null ? (<div className='loading'>Prüfe Formular ID! Bitte warten...</div>) : fiIdFound === false ? (
							<div className='error'>Diese Anfrage kann im System nicht gefunden werden.</div>) : (<div>
						<h3>Fügen Sie Dateien Ihrer Anfrage hinzu</h3>
						<p>Ihre Anfrage wurde bereits erhalten. Fügen Sie nun weitere Dateien hinzu.</p>
						<h4>Dateien hinzufügen</h4>
						<Dropzone
							getUploadParams={getUploadParams}
							onChangeStatus={handleChangeStatus}
							onSubmit={handleSubmit}
							inputContent={InputContent}
							maxSizeBytes={maxFileSize}
							maxFiles={3}
							inputWithFilesContent={InputContent}
							accept="*"
							PreviewComponent={FilePreview}
							SubmitButtonComponent={SubmitButtonInput}
							submitButtonContent={'Weitere Dateien hochladen'}

						/>

						{uploadedFiles.length > 0 && (<div>
						<h4>Hochgeladene Dateien {uploadedFiles.length > 0 ? `(${uploadedFiles.length})` : ''}</h4>
						<div className='uploadedFiles'>
						{uploadedFiles.map((f, i) => {
							return (
								<div key={'file-' + i} className='uploaded-file'>
									<div className='file-text'>

										<div className='file-name'><span title={f.name}>{f.name}</span></div>
										<div className='file-size'>{humanFileSize(f.size)}</div>
									</div>
									<div className='delete'><button disabled={isDeletingFiles} onClick={() => deleteFile(f.id)}>Löschen</button></div>
								</div>
							)
						})}
						</div>
						</div>)}

						</div>)}
					</div>
				</div>
			</div>
		</div>
	</div>) : (
    <div className="Anfrageformular-Schritte">
      <div className='Schritte'>
		<div className={'Schritt ' + (activeStep == 0 ? 'aktiv' : '')}><span>1.</span> Kontakt</div>
		<div className={'Schritt ' + (activeStep == 1 ? 'aktiv' : '')}><span>2.</span> Teilespezifikation</div>
		<div className={'Schritt ' + (activeStep == 2 ? 'aktiv' : '')}><span>3.</span> Oberflächenspezifikation</div>
		<div className={'Schritt ' + (activeStep == 3 ? 'aktiv' : '')}><span>4.</span> Upload</div>
	  </div>
	
	
<form className={'Schritte-Formular ' + (loading ? 'Schritt-Loading' : '')} id="Schritt-Inhalt-0" method='post' action="" ref={formRef} onSubmit={(e) => {
	e.preventDefault();
	e.stopPropagation();
}}>
		<div className='Schritt-Inhalte'>
			<div className='Schritt-Inhalte' id="Schritt-Inhalt-0" style={{
				display: (activeStep == 0) ? 'block' : 'none'
			}}>
				<div className='Schritt-Inhalt-Errors'>
					{errors && (
						<div className='Schritt-Inhalt-Errors-Text'>Bitte überprüfen Sie Ihre Eingaben</div>
					)}
				</div>
				<div className='Schritt-Inhalt-Formular'>

					<div className={'Schritt-Inhalt-Formular-Element Schritt-Anrede ' + (errors && errors.Anrede ? 'Schritt-error' : '')}>
						<input type="radio" id="Anrede-Herr" name="Anrede" value="Herr"/>
						<label htmlFor="Anrede-Herr">Herr</label>
						<input type="radio" id="Anrede-Frau" name="Anrede" value="Frau"/>
						<label htmlFor="Anrede-Frau">Frau</label>
						<span className="Schritt-required">*</span>

						{errors && errors.Anrede && (
							<div className='Element-Fehlermeldung'>{errors.Anrede}</div>
						)}

					</div>

					<div className='Schritt-Inhalt-Formular-Wrapper'>
					<div className='Schritt-Inhalt-Formular-Container'>
						<div className={'Schritt-Inhalt-Formular-Element Schritt-Name ' + (errors && errors.Name ? 'Schritt-error' : '')}>
							<label htmlFor="Name">Name <span className="Schritt-required">*</span></label>
							<input type="text" id="Name" name="Name" required/>
							
							{errors && errors.Name && (
								<div className='Element-Fehlermeldung'>{errors.Name}</div>
							)}
						</div>

						<div className={'Schritt-Inhalt-Formular-Element  Schritt-Firma ' + (errors && errors.Firma ? 'Schritt-error' : '')}>
							<label htmlFor="Firma">Firma</label>
							<input type="text" id="Firma" name="Firma" />

							{errors && errors.Firma && (
								<div className='Element-Fehlermeldung'>{errors.Firma}</div>
							)}
						</div>
					</div>

					<div className='Schritt-Inhalt-Formular-Container'>
						<div className={'Schritt-Inhalt-Formular-Element Schritt-Telefon ' + (errors && errors.Telefon ? 'Schritt-error' : '')}>
							<label htmlFor="Telefon">Telefon</label>
							<input type="tel" id="Telefon" name="Telefon" />

							{errors && errors.Telefon && (
								<div className='Element-Fehlermeldung'>{errors.Telefon}</div>
							)}
						</div>

						<div className={'Schritt-Inhalt-Formular-Element Schritt-Email ' + (errors && errors.Email ? 'Schritt-error' : '')}>
							<label htmlFor="Email">E-Mail <span className="Schritt-required">*</span></label>
							<input type="email" id="Email" name="Email" required/>

							{errors && errors.Email && (
								<div className='Element-Fehlermeldung'>{errors.Email}</div>
							)}
						</div>
					</div>
					</div>

					<div className={'Schritt-Inhalt-Formular-Element Schritt-Datenschutzbestimmungen ' + (errors && errors['Datenschutz'] ? 'Schritt-error' : '')}>
						<input type="checkbox" id="Datenschutz" name="Datenschutz" required/>
						<label htmlFor="Datenschutz" className='Schritt-Datenschutz'>Ich habe die <a href="/datenschutzerklaerung/" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere diese.</label>
						<span className="Schritt-required">*</span>
						{errors && errors.Datenschutz && (
							<div className='Element-Fehlermeldung'>{errors.Datenschutz}</div>
						)}
					</div>

				</div>
				<div className='Schritt-Inhalt-Buttons'>
					<div></div>
					<button className='Schritt-Inhalt-Button' onClick={handleNext} disabled={loading}>Weiter</button>
				</div>
			</div>
			
			<div className='Schritt-Inhalte' id="Schritt-Inhalt-1" style={{
				display: (activeStep == 1) ? 'block' : 'none'
			}}>
				<div className='Schritt-Inhalt-Formular'>

					<div className='Schritt-Error-Element'>
						{errors && (
							<div className='Element-Fehlermeldung'>Bitte füllen Sie alle Pflichtfelder aus.</div>
						)}
					</div>

					<div className={'Schritt-Inhalt-Formular-Element Schritt-Teil ' + (errors && errors.Teil ? 'Schritt-error' : '')}>
						<label htmlFor="Teil">Um was für ein Teil handelt es sich?</label>
						<div>
							<input type="text" id="Teil" name="Teil" />
						</div>

						{errors && errors.Teil && (
							<div className='Element-Fehlermeldung'>{errors.Teil}</div>
						)}
					</div>

					<div className='Schritt-Inhalt-Formular-Element Schritt-Zweck'>
						<label>Wofür benötigen Sie das Modell?</label>
						<div>
							<input type="checkbox" id="Ansicht-Praesentation" name="Modellzweck[]" value="Ansicht / Präsentation"/>
							<label htmlFor="Ansicht-Praesentation">zur Ansicht / Präsentation</label>
						</div>
						<div>
							<input type="checkbox" id="Konstruktionsueberpruefung" name="Modellzweck[]" value="Konstruktionsüberprüfung"/>
							<label htmlFor="Konstruktionsueberpruefung">zur Konstruktionsüberprüfung</label>
						</div>
						<div>
							<input type="checkbox" id="Funktionsueberpruefung" name="Modellzweck[]" value="Funktionsüberprüfung (verclipsen / verschrauben)"/>
							<label htmlFor="Funktionsueberpruefung">zur Funktionsüberprüfung (verclipsen / verschrauben)</label>
						</div>
					</div>


					<div className='Schritt-Inhalt-Formular-Element Schritt-Eigenschaften'>
						<label>Welche Eigenschaften muss das Modell aufweisen?</label>
						<div>
							<input type="checkbox" id="Temperaturbestaendigkeit" name="ModellEigenschaften[]" value="Temperaturbeständigkeit"/>
							<label htmlFor="Temperaturbestaendigkeit">Temperaturbeständigkeit</label>
						</div>
						<div>
							<input type="checkbox" id="Schlagzaehigkeit" name="ModellEigenschaften[]" value="Schlagzähigkeit"/>
							<label htmlFor="Schlagzaehigkeit">Schlagzähigkeit</label>
						</div>
					</div>

					<div className='Schritt-Inhalt-Formular-Element Schritt-Anzahl'>
						<label>Wie viele gleiche Modelle benötigen Sie?</label>
						<div>
							<input type="text" id="Anzahl" name="Anzahl" /> Stück
						</div>
					</div>



				</div>
				<div className='Schritt-Inhalt-Buttons'>
					<button className='Schritt-Inhalt-Button Button-Zurueck' onClick={() => setActiveStep(0)}>Zurück</button>
					<button className='Schritt-Inhalt-Button' onClick={handleNext} disabled={loading}>
						Weiter
					</button>
				</div>
			</div>
			
			<div className='Schritt-Inhalte' id="Schritt-Inhalt-2"  style={{
				display: (activeStep == 2) ? 'block' : 'none'
			}}>
				<div className='Schritt-Inhalt-Formular'>
				<div className='Schritt-Inhalt-Formular-Element  Schritt-Farbe'>
					<label>Welche Farbe soll das Modell haben?</label>
					<div>
						<input type="checkbox" id="Transparent" name="ModellFarbe[]" value="komplett transparent"/>
						<label htmlFor="Transparent">komplett transparent</label>
					</div>
					<div>
						<input type="checkbox" id="Lackiert" name="ModellFarbe[]" value="lackiert in RAL"/>
						<label htmlFor="Lackiert">lackiert in RAL</label>
					</div>
					<div>
						<input type="checkbox" id="Durchgefaerbt" name="ModellFarbe[]" value="durchgefärbt in RAL"/>
						<label htmlFor="Durchgefaerbt">durchgefärbt in RAL</label>
					</div>
					<div>
						<input type="checkbox" id="Verchromt" name="ModellFarbe[]" value="verchromt"/>
						<label htmlFor="Verchromt">eingefärbt in RAL</label>
					</div>
					<div>
						<input type="checkbox" id="Unbehandelt" name="ModellFarbe[]" value="unbehandelt"/>
						<label htmlFor="Unbehandelt">unbehandelt</label>
					</div>
					<div>
						<input type="checkbox" id="Mehrfarbig" name="ModellFarbe[]" value="mehrfarbig gedruckt (VRML-Format oder STL-Format mit Farbzuordnung)"/>
						<label htmlFor="Mehrfarbig">mehrfarbig gedruckt (VRML-Format oder STL-Format mit Farbzuordnung)</label>
					</div>
					<div>
						<label htmlFor="Andere">andere</label>
						<input type="text" id="Andere" name="ModellFarbeAndere"/>
					</div>
				</div>

				<div className='Schritt-Inhalt-Formular-Element Schritt-Oberflaeche'>
					<label>Welche Oberfläche soll das Modell haben?</label>
					<div>
						<input type="checkbox" id="Erodierstruktur" name="ModellOberflaeche[]" value="Erodierstruktur"/>
						<label htmlFor="Erodierstruktur">Erodierstruktur</label>
					</div>
					<div>
						<input type="checkbox" id="Poliert" name="ModellOberflaeche[]" value="poliert"/>
						<label htmlFor="Poliert">poliert</label>
					</div>
					<div>
						<input type="checkbox" id="Glatt" name="ModellOberflaeche[]" value="glatt"/>
						<label htmlFor="Glatt">glatt</label>
					</div>
					<div>
						<input type="checkbox" id="Grob" name="ModellOberflaeche[]" value="grob"/>
						<label htmlFor="Grob">grob</label>
					</div>
					<div>
						<input type="checkbox" id="UnbehandeltFlaeche" name="ModellOberflaeche[]" value="unbehandelt"/>
						<label htmlFor="UnbehandeltFlaeche">unbehandelt</label>
					</div>
				</div>

				<div className='Schritt-Inhalt-Formular-Element Schritt-Beschriftung'>
					<label>Soll das Modell beschriftet werden?</label>
					<div>
						<input type="checkbox" id="Bedruckt" name="ModellBeschriftung[]" value="bedruckt"/>
						<label htmlFor="Bedruckt">bedruckt</label>
					</div>
					<div>
						<input type="checkbox" id="Gelasert" name="ModellBeschriftung[]" value="gelasert"/>
						<label htmlFor="Gelasert">gelasert</label>
					</div>
					<div>
						<input type="checkbox" id="Nein" name="ModellBeschriftung[]" value="nein"/>
						<label htmlFor="Nein">nein</label>
					</div>
				</div>

				<div className='Schritt-Inhalt-Formular-Element Schritt-Besonderheiten'>
					<label htmlFor="Besonderheiten">Welche Besonderheiten gibt es?</label>
					<textarea id="Besonderheiten" name="Besonderheiten"></textarea>
				</div>

				<div className='Schritt-Inhalt-Formular-Element Schritt-CAD-Format'>
					<label htmlFor="CAD-Datenformat">Die CAD-Daten senden wir im Format:</label>
					<input type="text" id="CAD-Datenformat" name="CAD-Datenformat" />

					<label>per:</label>
					<div>
						<input type="checkbox" id="FormatEmail" name="Sendungsmethode[]" value="E-Mail"/>
						<label htmlFor="FormatEmail">E-Mail</label>
					</div>
					<div>
						<input type="checkbox" id="FTP-Server" name="Sendungsmethode[]" value="FTP-Server" onChange={(e) => {
							if(e.target.checked) {
								setFtpServerData(true);
							} else {
								setFtpServerData(false);
							}
						}}/>
						<label htmlFor="FTP-Server">FTP-Server</label>
					</div>
					{ftpServerData ? <div className='innerInfo'>
						<div>
							<label htmlFor="FTP-Server-host">FTP-Server Host</label>
							<input type="text" id="FTP-Server-host" name="FTPHost" />
						</div>
						<div>
							<label htmlFor="FTP-Server-user">FTP-Username</label>
							<input type="text" id="FTP-Server-user" name="FTPUser" />
						</div>
						<div>
							<label htmlFor="FTP-Server-password">FTP-Password</label>
							<input type="password" id="FTP-Server-password" name="FTPPassword" />
						</div>
					</div> : null}
					<div>
						<input type="checkbox" id="Upload" name="Sendungsmethode[]" value="Upload"/>
						<label htmlFor="Upload">Upload</label>
					</div>
				</div>





				</div>
				<div className='Schritt-Inhalt-Buttons'>
					<button type="button" className='Schritt-Inhalt-Button' onClick={() => { setActiveStep(activeStep - 1) }}>Zurück</button>
					<button type="submit" className='Schritt-Inhalt-Button Absenden-Button' onClick={handleNext} 
						disabled={loading}
					>Absenden</button>
				</div>
			</div>
			{activeStep === 3 && (<div className='Schritt-Ende'>
				<h3>Vielen Dank für Ihre Anfrage!</h3>
				<p>Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.</p>
				<h4>Sie können nun Dateien zur Anfrage hinzufügen</h4>
				<Dropzone
					getUploadParams={getUploadParams}
					onChangeStatus={handleChangeStatus}
					onSubmit={handleSubmit}
					inputContent={InputContent}
					maxSizeBytes={maxFileSize}
					maxFiles={3}
					inputWithFilesContent={InputContent}
					accept="*"
					PreviewComponent={FilePreview}
					SubmitButtonComponent={SubmitButtonInput}
					submitButtonContent={'Weitere Dateien hochladen'}

					/>

					{uploadedFiles.length > 0 && (<div>
						<h4>Hochgeladene Dateien {uploadedFiles.length > 0 ? `(${uploadedFiles.length})` : ''}</h4>
						<div className='uploadedFiles'>
						{uploadedFiles.map((f, i) => {
							return (
								<div key={'file-' + i} className='uploaded-file'>
									<div className='file-text'>

										<div className='file-name'><span title={f.name}>{f.name}</span></div>
										<div className='file-size'>{humanFileSize(f.size)}</div>
									</div>
									<div className='delete'><button disabled={isDeletingFiles} onClick={() => deleteFile(f.id)}>Löschen</button></div>
								</div>
							)
						})}
						</div>
					</div>)}
			</div>)}
		</div>
</form>
			
	<style jsx>{`
		.Schritt.aktiv {
		}
		`}</style>
    </div>
	)}
	<div className='snackbar'>
				{snackbarMessage ? <div className='snackbar-item'>
						<div>{snackbarMessage}</div>
						<div><span onClick={() => {setSnackbarMessage(null)}}>Schließen</span></div>
					</div> : null}
			</div>
	</div>
  );
}

export default App;
