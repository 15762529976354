
const statusValues = {
	"rejected_file_type": "Dateityp abgelehnt",
	"rejected_max_files": "Max Dateien überschritten",
	"preparing": "Vorbereitung",
	"error_file_size": "Datei zu groß",
	"error_validation": "Validierungsfehler",
	"ready": "Bereit",
	"started": "Gestartet",
	"getting_upload_params": "Erhalte Upload-Parameter",
	"error_upload_params": "Fehlerhafte Upload-Parameter",
	"uploading": "Hochladen",
	"exception_upload": "Upload-Ausnahme",
	"aborted": "Abgebrochen",
	"restarted": "Neu gestartet",
	"removed": "Entfernt",
	"error_upload": "Fehlerhafter Upload",
	"headers_received": "Header empfangen",
	"done": "Abgeschlossen"
  };

function getStatusText(status) {
	return statusValues[status] || status;
}

const FilePreview = ({ meta }) => {
	const { name, percent, status } = meta
	return (
	  <div className={`status_${status} fileEntry`}>
		<div className="file">
			<div className="name">{name} <span>({Math.ceil(percent)}%)</span></div>
			<div className="status">{getStatusText(status)}</div>
		</div>
		<div className="progress-bar">
			<div className="progress" style={{ width: `${percent}%` }} />
		</div>

	  </div>
	)
  }

export default FilePreview;